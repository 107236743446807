import React from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Column } from 'components/grid/Column';

interface WorkBlockTextLeftProps {
  title: string;
  services: string;
  extras: string;
  mobileOnly: string;
}

const WorkTitle = styled.div`
  ${responsiveFont(18, 24)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
`;

const WorkDetailsLeft = styled.div`
  display: block;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 10px;
  @media (min-width: ${breakpoints.md}) {
    text-align: right;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const MobileOnly = styled.div`
  display: block;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const WorkBlockExtras = styled.p`
  padding-top: 20px;
  ${responsiveFont(10, 12)};
`;

export const WorkBlockTextLeft = ({
  title,
  services,
  extras,
  mobileOnly,
}: WorkBlockTextLeftProps) => {
  if (!mobileOnly) {
    return (
      <>
        <Column
          md={2}
          lg={2}
          sm={12}
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <WorkDetailsLeft>
            <WorkTitle>{title}</WorkTitle>
            <p>{services}</p>
            <WorkBlockExtras>{extras}</WorkBlockExtras>
          </WorkDetailsLeft>
        </Column>
      </>
    );
  }
  return (
    <>
      <MobileOnly>
        <Column
          md={2}
          lg={2}
          sm={12}
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <WorkDetailsLeft>
            <WorkTitle>{title}</WorkTitle>
            <p>{services}</p>
            <WorkBlockExtras>{extras}</WorkBlockExtras>
          </WorkDetailsLeft>
        </Column>
      </MobileOnly>
    </>
  );
};
