import React from 'react';
import styled from 'styled-components';

import StarterLogo from 'assets/svg/trampolin-logo.svg';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Trampolin from 'assets/svg/trampolin.svg';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';

import { breakpoints } from 'styles/variables';

interface Social {
  icon: React.ReactNode;
  to: string;
}


const Content = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 40px 0;
`;


const List = styled.ul`
  display: flex;
`;

const ListItem = styled.li`
  margin-right: 30px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

const Logo = styled(StarterLogo)`
  display: block;

  height: 28px;
  width: auto;

  @media (min-width: ${breakpoints.md}) {
    height: 28px;
  }
`;

import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import Linkedin from 'assets/svg/linkedin.svg';


export const SimpleFooter = () => {
  const {languages, language, originalPath } = useI18next();

  const social = [
    { icon: <Instagram />, to: 'https://www.instagram.com/trampolin.studio' },
    { icon: <Facebook />, to: 'https://www.facebook.com/Trampolin.studio' },
    {
      icon: <Linkedin />,
      to: 'https://www.linkedin.com/company/trampolin-studio',
    }
  ]

  return (
  <Content>
    <List>
      {social.map((item) => (
        <ListItem key={item.to}>
          <a href={item.to} target="_blank" rel="noopener noreferrer">
            {item.icon}
          </a>
        </ListItem>
      ))}
    </List>
    <p className="absolute top-[115%] left-0 w-full md:static md:top-auto">© {new Date().getFullYear()} Trampolin&nbsp;&bull;&nbsp;      
      {languages.map((lng) => (
        <span key={lng} className={language !== lng ? 'inline' : 'hidden'}>
          <Link to={originalPath} language={lng}>
            {lng === 'en' ? 'English' : 'Slovenian'}
          </Link>
        </span>
      ))}
    </p>
    <AniLink paintDrip hex="#000" to="/">
      <Logo />
    </AniLink>
  </Content>
  )
};
